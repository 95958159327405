import { render, staticRenderFns } from "./Event.vue?vue&type=template&id=0ae7f555&scoped=true"
import script from "./Event.vue?vue&type=script&lang=js"
export * from "./Event.vue?vue&type=script&lang=js"
import style0 from "./Event.vue?vue&type=style&index=0&id=0ae7f555&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ae7f555",
  null
  
)

export default component.exports